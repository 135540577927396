import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { FaTrophy, FaChartBar, FaGift, FaHandshake } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const EsportsInfo = () => {
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 }
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen text-white p-8 ml-16 md:ml-64"> {/* Ajuste dinámico del margen izquierdo */}
      <Helmet>
        <title>eSports en Fifty Royal - Clash Royale</title>
        <meta name="description" content="Participa en emocionantes guerras de clanes de Clash Royale y gana premios. Únete a Fifty Royal y compite al más alto nivel." />
        <meta name="keywords" content="Fifty Royal, Clash Royale, esports, competencias de Clash Royale, torneos de Clash Royale, guerras de clanes" />
      </Helmet>
      
      <motion.div 
        className="max-w-4xl mx-auto space-y-8"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.header className="text-center" variants={itemVariants}>
          <h1 className="text-5xl font-extrabold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500">
            eSports en Fifty Royal
          </h1>
          <p className="text-xl text-gray-400">
            Donde la diversidad y la competitividad se encuentran para crear campeones.
          </p>
        </motion.header>

        {/* Secciones de eventos y competencias */}
        <motion.section variants={itemVariants}>
          <h2 className="text-3xl font-bold mb-6 text-yellow-400">Eventos y Competencias</h2>
          <div className="space-y-4">
            <EventCard 
              icon={<FaTrophy className="text-yellow-400" />}
              title="Clash Royale Clan Wars"
              description="Participa en nuestras guerras de clanes periódicas, donde ofrecemos premios para los mejores jugadores. Estas guerras son una oportunidad para demostrar habilidades tácticas y estratégicas en un ambiente competitivo."
            />
            <EventCard 
              icon={<FaChartBar className="text-blue-400" />}
              title="Estadísticas y Rendimiento"
              description={<>Para más detalles sobre las estadísticas de guerra y rendimiento del clan, visita nuestra página en <a href="https://royaleapi.com/clan/QRC2RG8C/war/analytics" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 transition duration-300">RoyaleAPI</a>.</>}
            />
            <EventCard 
              icon={<FaGift className="text-purple-400" />}
              title="Torneos con Premios"
              description="Organizamos torneos variados con premios incluidos, como camisetas o tarjetas de regalo, abiertos a jugadores de todas las habilidades."
            />
          </div>
        </motion.section>

        {/* Nueva sección de contacto para clanes */}
        <motion.section variants={itemVariants}>
          <h2 className="text-3xl font-bold mb-6 text-green-400">Contacto para Juegos Amistosos</h2>
          <p className="text-lg mb-4">
            ¿Interesado en enfrentarte a nuestro clan en un juego amistoso? ¡Contáctanos para organizar un encuentro!
          </p>
          <button 
            onClick={() => navigate('/contact-clans')}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 flex items-center"
          >
            <FaHandshake className="text-xl mr-2" />Contactar
          </button>
        </motion.section>
      </motion.div>
    </div>
  );
};

const EventCard = ({ icon, title, description }) => (
  <motion.div 
    className="bg-gray-800 bg-opacity-60 p-6 rounded-lg backdrop-filter backdrop-blur-sm"
    whileHover={{ scale: 1.02 }}
    transition={{ type: 'spring', stiffness: 300 }}
  >
    <div className="flex items-center mb-4">
      {icon}
      <h3 className="text-2xl font-bold ml-3 text-gray-100">{title}</h3>
    </div>
    <p className="text-gray-300">{description}</p>
  </motion.div>
);

export default EsportsInfo;
