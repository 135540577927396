const sendData = async (webhookUrl, data) => {
  console.log('sendData called with webhook URL:', webhookUrl);
  console.log('Data to be sent:', data);

  if (!webhookUrl) {
    console.error('Webhook URL is undefined or empty in sendData');
    throw new Error('Webhook URL is undefined or empty');
  }

  try {
    const response = await fetch(webhookUrl, {
      method: 'POST',
      body: data,
    });

    console.log('Response status:', response.status);
    console.log('Response OK:', response.ok);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response text:', errorText);
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    const responseData = await response.text();
    console.log('Response data:', responseData);

    return { success: true, message: 'Enviado con éxito' };
  } catch (error) {
    console.error('Error in sendData:', error);
    return { success: false, message: `Error al enviar. Detalles: ${error.message}` };
  }
};

export default sendData;