import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Youtube, Instagram } from 'lucide-react';

const NandoPage = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVideoLoaded(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title> Nando | Artista de Fifty Records </title>
        <meta name="description" content="Nando, artista de Fifty Records y creador de contenido. Descubre su música y únete a su comunidad." />
        <meta property="og:title" content="Nando | Artista de Fifty Records" />
        <meta property="og:description" content="Explora el universo musical de Nando. ¡Suscríbete y sigue para vivir el ritmo!" />
        <meta property="og:image" content="/images/nDn_Nando.jpg" />
        <meta property="og:url" content="https://fifyrecords.com/artistas/nando" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="min-h-screen relative overflow-hidden bg-purple-900">
        {/* Fondo estático */}
        <div className="absolute inset-0 bg-purple-900 bg-opacity-80 z-10"></div>
        
        {/* YouTube iframe como fondo */}
        {videoLoaded && (
          <div className="absolute inset-0 pointer-events-none">
            <iframe
              src="https://www.youtube.com/embed/XxJ5WZnahzw?autoplay=1&mute=1&loop=1&controls=0&showinfo=0&playlist=XxJ5WZnahzw"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="background video"
              className="absolute top-1/2 left-1/2 w-[300%] h-[300%] -translate-x-1/2 -translate-y-1/2"
              style={{ border: 'none' }}
            />
          </div>
        )}
        
        {/* Contenido principal */}
        <div className="relative z-20 container mx-auto px-4 py-12 text-white">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
            <motion.div 
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="mb-8 md:mb-0"
            >
              <h1 className="text-6xl font-bold mb-4">Nando</h1>
              <p className="text-2xl mb-8">Artista de Fifty Records y creador de contenido</p>
              <div className="flex flex-col space-y-4 w-full max-w-md">
                <motion.a
                  href="https://www.youtube.com/@nNdNando"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-red-600 text-white font-bold py-4 px-8 rounded-full flex items-center justify-between text-xl shadow-lg hover:bg-red-700 transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="flex items-center">
                    <Youtube className="mr-3" size={28} />
                    <span>YouTube</span>
                  </div>
                  <span>Suscribirse</span>
                </motion.a>
                <motion.a
                  href="https://www.instagram.com/ferferrari96/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-gradient-to-r from-purple-600 via-pink-600 to-red-600 text-white font-bold py-4 px-8 rounded-full flex items-center justify-between text-xl shadow-lg hover:from-purple-700 hover:via-pink-700 hover:to-red-700 transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="flex items-center">
                    <Instagram className="mr-3" size={28} />
                    <span>Instagram</span>
                  </div>
                  <span>Seguir</span>
                </motion.a>
              </div>
            </motion.div>
            <motion.div
              className="relative w-64 h-64"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              <img 
                src="/images/nDn_Nando.jpg" 
                alt="Nando" 
                className="rounded-full w-full h-full object-cover"
              />
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NandoPage;