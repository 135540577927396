import React from 'react';
import { Clock, Smartphone, Beaker, Music, Gamepad, TrendingUp } from 'lucide-react';

const timelineData = [
  {
    year: '2022',
    event: 'Inicio del proyecto',
    icon: Clock,
    detail: 'Responder a la creciente demanda de espacios para gamers y creadores de contenido.'
  },
  {
    year: '2022',
    event: 'Formación del equipo de Clash Royale',
    icon: Smartphone,
    detail: 'Fomentar la competitividad y el trabajo en equipo en juegos móviles populares.'
  },
  {
    year: '2023',
    event: 'Creación del laboratorio del club',
    icon: Beaker,
    detail: 'Proporcionar un espacio de innovación y experimentación para proyectos tecnológicos.'
  },
  {
    year: '2024',
    event: 'Lanzamiento del sello musical',
    icon: Music,
    detail: 'Apoyar el talento musical local y ofrecer una plataforma para artistas emergentes.'
  },
  {
    year: 'Próximamente',
    event: 'Apertura del equipo de Brawl Stars',
    icon: Gamepad,
    detail: 'Expandir nuestra presencia en la escena competitiva de juegos móviles.'
  },
  {
    year: '2025',
    event: 'Expansión futura',
    icon: TrendingUp,
    detail: 'Ampliar nuestro alcance y servicios para satisfacer las necesidades cambiantes de la comunidad.'
  },
];

const Logo = () => (
  <div className="text-center mb-8">
    <div className="inline-block bg-yellow-400 text-purple-800 text-4xl font-bold py-2 px-4 rounded-lg">
      Inicio
    </div>
  </div>
);

const Timeline = () => {
  return (
    <div className="bg-gray-900 p-8 text-white font-sans">
      <Logo />
      <div className="relative">
        <div className="absolute left-1/2 transform -translate-x-px w-0.5 h-full bg-yellow-400"></div>
        <div className="space-y-16">
          {timelineData.map((item, index) => (
            <div key={index} className={`flex ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
              <div className="w-1/2 px-4">
                <div className={`bg-purple-800 p-4 rounded-lg shadow ${index % 2 === 0 ? 'text-right' : 'text-left'}`}>
                  <div className="text-lg font-bold text-yellow-400 mb-1">{item.year}</div>
                  <div className="text-md font-semibold mb-2">{item.event}</div>
                  <div className="text-sm text-purple-200">{item.detail}</div>
                </div>
              </div>
              <div className="relative flex items-center justify-center w-12">
                <div className="w-12 h-12 absolute rounded-full bg-purple-600 border-4 border-yellow-400 flex items-center justify-center z-10">
                  <item.icon className="w-6 h-6 text-yellow-400" />
                </div>
              </div>
              <div className="w-1/2"></div>
            </div>
          ))}
        </div>
      </div>
      <div className="text-center mt-12">
        <p className="text-2xl font-bold text-yellow-400">¡El futuro es prometedor!</p>
      </div>
    </div>
  );
};

export default Timeline;