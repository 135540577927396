import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 py-8">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0">
        <div className="flex space-x-6">
          <a href="https://x.com/Fifty_Royal" target="_blank" rel="noopener noreferrer">
            <img src="/images/twitter.png" alt="Twitter" className="h-8 w-8" />
          </a>
          <a href="https://instagram.com/FiftyRoyal" target="_blank" rel="noopener noreferrer">
            <img src="/images/instagram.png" alt="Instagram" className="h-8 w-8" />
          </a>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
