import React, { useMemo } from 'react';
import { FaTrophy } from 'react-icons/fa';
import clanInfo from './clanInfo.json';

const Podium = () => {
  const topDonators = useMemo(() => {
    return [...clanInfo.memberList]
      .sort((a, b) => b.donations - a.donations)
      .slice(0, 3);
  }, []);

  const medalColors = ['#FFD700', '#C0C0C0', '#CD7F32'];

  return (
    <div className="bg-gray-900 p-6 mb-6 rounded-lg shadow-xl text-center">
      <h2 className="text-xl font-semibold mb-4 text-white">Top 3 Donadores</h2>
      <div className="flex justify-around items-center">
        {topDonators.map((member, index) => (
          <div key={member.tag} className="flex flex-col items-center">
            <FaTrophy style={{ color: medalColors[index], fontSize: '3rem' }} />
            <div className="text-lg font-semibold mt-2">{member.name}</div>
            <div className="text-sm mt-1">Donaciones: {member.donations}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Podium;
