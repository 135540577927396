import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import exercises from './exercises'; // Importamos los ejercicios
import { restrictToParentElement } from '@dnd-kit/modifiers';

function CustomRoutineCreator({ onRoutineCreated = () => {} }) {
  const [selectedExerciseIds, setSelectedExerciseIds] = useState([]);
  const [selectedMuscleGroups, setSelectedMuscleGroups] = useState([]);
  const [routineName, setRoutineName] = useState('');
  const [restTime, setRestTime] = useState(15);
  const [exerciseDuration, setExerciseDuration] = useState(30);
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleExerciseSelection = (exerciseId) => {
    const idString = exerciseId.toString();
    setSelectedExerciseIds((prev) =>
      prev.includes(idString) ? prev.filter((id) => id !== idString) : [...prev, idString]
    );
  };

  const handleMuscleGroupSelection = (group) => {
    setSelectedMuscleGroups((prev) =>
      prev.includes(group) ? prev.filter((g) => g !== group) : [...prev, group]
    );
  };

  const filteredExercises = selectedMuscleGroups.length
    ? exercises.filter((ex) => selectedMuscleGroups.includes(ex.muscleGroup))
    : exercises;

  const handleCreateRoutine = () => {
    if (!routineName.trim()) {
      alert('Por favor, introduce un nombre para la rutina.');
      return;
    }

    if (restTime <= 0 || exerciseDuration <= 0 || selectedExerciseIds.length === 0) {
      alert('Por favor, verifica los campos.');
      return;
    }

    const customRoutine = selectedExerciseIds.map((id) => {
      const exercise = exercises.find((ex) => ex.id.toString() === id);
      return {
        ...exercise,
        duration: exerciseDuration,
      };
    });

    onRoutineCreated(customRoutine, routineName, restTime);
  };

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    if (over && selectedExerciseIds.includes(over.id)) {
      if (active.id !== over.id) {
        setSelectedExerciseIds((items) => {
          const oldIndex = items.indexOf(active.id);
          const newIndex = items.indexOf(over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
  };

  return (
    <div className="p-4 max-w-md mx-auto bg-gray-900 text-white rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Creador de Rutinas Personalizadas</h2>

      {/* Campo de texto para el nombre de la rutina */}
      <div className="mb-4">
        <label htmlFor="routineName" className="block text-lg font-semibold mb-2">Nombre de la rutina:</label>
        <input
          type="text"
          id="routineName"
          value={routineName}
          onChange={(e) => setRoutineName(e.target.value)}
          className="w-full p-3 bg-gray-800 border border-gray-700 rounded text-white"
          placeholder="Introduce un nombre para tu rutina"
        />
      </div>

      {/* Selección de grupos musculares */}
      <div className="mb-4">
        <h3 className="font-semibold">Selecciona grupos musculares:</h3>
        <div className="flex flex-wrap gap-2 mt-2">
          {['Abdomen', 'Piernas', 'Brazos'].map((group) => (
            <label key={group} className="flex items-center space-x-2">
              <input 
                type="checkbox" 
                checked={selectedMuscleGroups.includes(group)}
                onChange={() => handleMuscleGroupSelection(group)}
                className="h-4 w-4"
                aria-label={`Seleccionar grupo muscular ${group}`}
              />
              <span>{group}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Selección de ejercicios */}
      <div className="mb-4">
        <h3 className="font-semibold">Selecciona los ejercicios:</h3>
        <div className="grid grid-cols-2 gap-2 mt-2">
          {filteredExercises.map((exercise) => (
            <label key={exercise.id} className="flex items-center space-x-2">
              <input 
                type="checkbox" 
                checked={selectedExerciseIds.includes(exercise.id.toString())}
                onChange={() => handleExerciseSelection(exercise.id)}
                className="h-4 w-4"
                aria-label={`Seleccionar ejercicio ${exercise.name}`}
              />
              <span>{exercise.name}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Arrastrar y Soltar para ordenar los ejercicios */}
      <div className="mb-4">
        <h3 className="font-semibold">Organiza los ejercicios seleccionados:</h3>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToParentElement]}
        >
          <SortableContext
            items={selectedExerciseIds}
            strategy={verticalListSortingStrategy}
          >
            <ul className="mt-2 bg-gray-800 p-2 rounded-lg min-h-[100px] border border-gray-700">
              {selectedExerciseIds.map((id) => {
                const exercise = exercises.find((ex) => ex.id.toString() === id);
                return (
                  <SortableItem key={id} id={id} name={exercise.name} />
                );
              })}
            </ul>
          </SortableContext>

          <DragOverlay>
            {activeId ? (
              <div className="p-2 bg-gray-800 rounded-lg shadow-md text-white">
                {exercises.find((ex) => ex.id.toString() === activeId)?.name}
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>

      {/* Campos para duración y descanso */}
      <div className="mb-4">
        <label className="block font-semibold mb-2">Duración de cada ejercicio (segundos):</label>
        <input
          type="number"
          value={exerciseDuration}
          onChange={(e) => setExerciseDuration(Number(e.target.value))}
          className="w-full p-3 bg-gray-800 border border-gray-700 rounded text-white"
          placeholder="Introduce la duración por ejercicio"
          min="10"
        />
      </div>

      <div className="mb-4">
        <label className="block font-semibold mb-2">Tiempo de descanso entre ejercicios (segundos):</label>
        <input
          type="number"
          value={restTime}
          onChange={(e) => setRestTime(Number(e.target.value))}
          className="w-full p-3 bg-gray-800 border border-gray-700 rounded text-white"
          placeholder="Introduce el tiempo de descanso"
          min="5"
        />
      </div>

      <button 
        onClick={handleCreateRoutine}
        className="w-full bg-green-600 hover:bg-green-700 text-white py-3 px-4 rounded-lg transition-all duration-300"
        aria-label="Crear rutina personalizada"
      >
        Crear Rutina
      </button>
    </div>
  );
}

CustomRoutineCreator.propTypes = {
  onRoutineCreated: PropTypes.func,
};

export default CustomRoutineCreator;
