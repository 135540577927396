import React, { useMemo } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FaCrown, FaUserTie, FaUserFriends } from 'react-icons/fa';
import clanInfo from './clanInfo.json';  // Importa el JSON que contiene toda la información del clan

const renderCustomLabel = ({ name, percent, value }) => (
  `${name} (${(percent * 100).toFixed(1)}% - ${value} miembros)`
);

const renderCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0];
    return (
      <div className="bg-gray-700 text-white p-2 rounded shadow-lg">
        <p className="text-sm font-semibold">{name}</p>
        <p className="text-sm">Miembros: {value}</p>
      </div>
    );
  }
  return null;
};

const RoleDistributionChart = ({ colors }) => {
  // Calcular la distribución de roles a partir de los datos en clanInfo
  const data = useMemo(() => {
    const distribution = clanInfo.memberList.reduce((acc, member) => {
      acc[member.role] = (acc[member.role] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(distribution).map(([role, count]) => ({ name: role, value: count }));
  }, []);

  const roleIcons = {
    Leader: <FaCrown className="text-yellow-500" />,
    CoLeader: <FaUserTie className="text-green-500" />,
    Member: <FaUserFriends className="text-blue-500" />
  };

  return (
    <div className="bg-gray-800 shadow-lg rounded-lg p-6 hover:shadow-2xl transition-shadow duration-300">
      <h2 className="text-2xl font-semibold mb-4 text-pink-400 flex items-center">
        {roleIcons.Leader} <span className="ml-2">Distribución de Roles</span>
      </h2>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie 
            data={data} 
            dataKey="value" 
            nameKey="name" 
            label={renderCustomLabel}
            animationDuration={800}
            animationEasing="ease-out"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Tooltip content={renderCustomTooltip} />
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            iconType="circle"
            iconSize={10}
            formatter={(value) => (
              <span className="text-gray-300">{roleIcons[value]} {value}</span>
            )}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RoleDistributionChart;
