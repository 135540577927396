// exercises.js
const exercises = [
  {
    id: 1,
    name: 'Abdominales',
    muscleGroup: 'Abdomen',
    duration: 30,
  },
  {
    id: 2,
    name: 'Saltos',
    muscleGroup: 'Piernas',
    duration: 30,
  },
  {
    id: 3,
    name: 'Crunches',
    muscleGroup: 'Abdomen',
    duration: 30,
  },
  {
    id: 4,
    name: 'Plancha',
    muscleGroup: 'Abdomen',
    duration: 30,
  },
  {
    id: 5,
    name: 'Mountain Climbers',
    muscleGroup: 'Piernas',
    duration: 30,
  },
  {
    id: 6,
    name: 'Abro y cierro',
    muscleGroup: 'Piernas',
    duration: 30,
  },
  {
    id: 7,
    name: 'Sentadillas',
    muscleGroup: 'Piernas',
    duration: 30,
  },
  {
    id: 8,
    name: 'Flexiones de brazos',
    muscleGroup: 'Brazos',
    duration: 30,
  },
];

export default exercises;
