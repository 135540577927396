import React from 'react';
import { Link } from 'react-router-dom';
import { Gamepad2, Music, Dumbbell, ExternalLink } from 'lucide-react';

const divisions = [
  {
    title: 'Fifty Gaming',
    icon: Gamepad2,
    description: 'División principal de esports. Organizamos torneos y competiciones de Clash Royale.',
    color: 'border-violet-500',
    link: '/esports-info', // Agregar el enlace aquí
  },
  {
    title: 'Fifty Records',
    icon: Music,
    description: 'Nuestro sello discográfico. Promocionamos música a través de YouTube y plataformas digitales.',
    color: 'border-yellow-500',
    link: '/records-info', // Agregar el enlace aquí
  },
  {
    title: 'Fifty Fitness',
    icon: Dumbbell,
    description: 'División de entrenamiento físico. Promovemos un estilo de vida saludable.',
    color: 'border-green-500',
    link: '/training', // Agregar el enlace aquí
  }
];

const DivisionCard = ({ title, icon: Icon, description, color, link }) => {
  return (
    <div className={`bg-gray-900 p-6 rounded-lg border-l-4 ${color} hover:bg-gray-800 transition-colors`}>
      <div className="flex items-center mb-4">
        <Icon size={24} className="text-violet-400 mr-3" />
        <h3 className="text-xl font-bold text-white">{title}</h3>
      </div>
      <p className="text-gray-400">{description}</p>
      <Link to={link} className="mt-4 text-violet-400 hover:text-violet-300 flex items-center text-sm">
        Saber más
        <ExternalLink size={16} className="ml-2" />
      </Link>
    </div>
  );
};

const FiftyUniverse = () => {
  return (
    <section id="divisiones" className="px-4 bg-gray-900/50">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold mb-4 text-center">DIVISIONES</h2>
        <p className="text-gray-400 text-center mb-12">Explora todas nuestras áreas de actividad</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {divisions.map((division) => (
            <DivisionCard key={division.title} {...division} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FiftyUniverse;
