import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FormClanContact = () => {
  const [formData, setFormData] = useState({
    clanName: '',
    email: '',
    message: ''
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_CONTACT_CLANS;

    if (!webhookUrl) {
      console.error('Webhook URL is missing');
      alert('Error de configuración. Por favor, verifica la configuración del sistema.');
      return;
    }

    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: `Nuevo contacto de juego amistoso:\n- Nombre del clan: ${formData.clanName}\n- Correo electrónico: ${formData.email}\n- Mensaje: ${formData.message}`
        })
      });

      if (response.ok) {
        alert('Formulario enviado. Nos pondremos en contacto pronto!');
        navigate('/'); // Redirige al usuario a la página principal después del envío
      } else {
        const errorText = await response.text();
        throw new Error(`Failed to send data to Discord: ${errorText}`);
      }
    } catch (error) {
      console.error('Error al enviar los datos al servidor de Discord:', error);
      alert('Error al enviar el formulario. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen flex items-center justify-center p-8">
      <form className="bg-white p-6 rounded shadow-md" onSubmit={handleSubmit}>
        <h2 className="text-2xl font-bold mb-4 text-gray-900">Contacto para Juegos Amistosos</h2>
        <div className="mb-4">
          <label htmlFor="clanName" className="block text-gray-700 text-sm font-bold mb-2">Nombre del Clan:</label>
          <input type="text" id="clanName" name="clanName" value={formData.clanName} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Correo Electrónico:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">Mensaje:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" required></textarea>
        </div>
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Enviar
        </button>
      </form>
    </div>
  );
};

export default FormClanContact;
