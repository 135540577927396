import React, { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import ClanInfo from './ClanInfo';
import RoleDistributionChart from './RoleDistributionChart';
import TrophyDistributionChart from './TrophyDistributionChart';
import MembersList from './MembersList';
import Podium from './Podium';
import clanInfo from './clanInfo.json'; // Importa el JSON generado
import { COLORS } from './constants';
import { roleHierarchy } from './config';

const Dashboard = ({ sidebarExpanded }) => {
  const [sortCriteria, setSortCriteria] = useState('trophies'); // Agregado
  const [filterPlayer, setFilterPlayer] = useState(''); // Si también se necesita filtrar jugadores

  const sortedMembers = useMemo(() => {
    return [...clanInfo.memberList]
      .sort((a, b) => {
        if (sortCriteria === 'role') {
          return roleHierarchy.indexOf(a.role) - roleHierarchy.indexOf(b.role);
        }
        return b[sortCriteria] - a[sortCriteria];
      })
      .filter(member => member.name.toLowerCase().includes(filterPlayer.toLowerCase())); // Si el filtro está habilitado
  }, [sortCriteria, filterPlayer]);

  const roleDistribution = useMemo(() => {
    const distribution = clanInfo.memberList.reduce((acc, member) => {
      acc[member.role] = (acc[member.role] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(distribution).map(([role, count]) => ({ name: role, value: count }));
  }, []);

  return (
    <div className={`flex min-h-screen bg-gradient-to-b from-gray-900 to-black text-white transition-all duration-300 ${sidebarExpanded ? 'ml-64' : 'ml-16'} p-8`}>
      <motion.div
        className="max-w-6xl mx-auto space-y-12"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <header className="text-center">
          <motion.h1
            className="text-6xl font-extrabold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-500"
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ type: 'spring', stiffness: 300, damping: 10 }}
          >
            Fifty Royal Dashboard
          </motion.h1>
          <p className="text-lg text-gray-300 mb-6">
            Controla y analiza el desempeño del clan
          </p>
        </header>

        <motion.section
          className="bg-gray-800 bg-opacity-50 p-8 rounded-2xl backdrop-blur-sm"
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }}
        >
          <h2 className="text-3xl font-bold mb-4 text-center">Estadísticas del Clan</h2>
          <ClanInfo clanInfo={clanInfo} />
          <Podium members={clanInfo.memberList} />
          <RoleDistributionChart data={roleDistribution} colors={COLORS} />
          <TrophyDistributionChart data={sortedMembers} colors={COLORS} />
        </motion.section>

        <motion.section
          className="bg-gray-700 p-8 rounded-lg"
          whileHover={{ scale: 1.02 }}
          transition={{ type: 'spring', stiffness: 300, damping: 10 }}
        >
          <h2 className="text-3xl font-bold mb-4 text-center">Miembros del Clan</h2>
          <MembersList
            members={sortedMembers}
            sortCriteria={sortCriteria}
            setSortCriteria={setSortCriteria} // Si quieres permitir cambiar la forma de ordenar
            filterPlayer={filterPlayer}
            setFilterPlayer={setFilterPlayer} // Si quieres permitir filtrar
          />
        </motion.section>
      </motion.div>
    </div>
  );
};

export default Dashboard;
