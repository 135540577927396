import { formatDistanceToNow, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

export const formatLastSeen = (dateString) => {
  if (!dateString) {
    console.warn("No date string provided.");
    return 'No disponible';
  }

  try {
    // Normaliza el string al formato ISO
    const normalizedDateString = dateString.replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})\.\d{3}Z/, 
      '$1-$2-$3T$4:$5:$6Z');
    const date = parseISO(normalizedDateString);

    if (isNaN(date.getTime())) {
      console.error(`Invalid date provided: ${dateString}`);
      return 'Fecha no disponible';
    }

    // Calcula los días desde la última conexión
    const daysSinceLastSeen = formatDistanceToNow(date, { addSuffix: false, locale: es });
    return daysSinceLastSeen;
  } catch (error) {
    console.error('Error al parsear la fecha:', error);
    return 'Fecha no disponible';
  }
};
