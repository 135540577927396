import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import clanInfo from './clanInfo.json';  // Importa el JSON que contiene toda la información del clan

export const calculateTrophyDistribution = (members) => {
  const ranges = ['0-2000', '2001-4000', '4001-6000', '6001-8000', '8001+'];
  const distribution = members.reduce((acc, member) => {
    const range = ranges.find((r, i) => {
      const [, max] = r.split('-').map(Number);
      return member.trophies <= max || i === ranges.length - 1;
    });
    acc[range] = (acc[range] || 0) + 1;
    return acc;
  }, {});
  return ranges.map(range => ({ name: range, value: distribution[range] || 0 }));
};

const TrophyDistributionChart = () => {
  // Calcular la distribución de trofeos usando los datos de clanInfo
  const data = useMemo(() => calculateTrophyDistribution(clanInfo.memberList), []);

  const getBarColor = (trophies) => {
    if (trophies > 8000) return "#FFD700"; // Oro para trofeos > 8000
    if (trophies > 6000) return "#C0C0C0"; // Plata para trofeos > 6000
    if (trophies > 4000) return "#D6BCFA"; // Violeta suave para trofeos > 4000
    return "#A78BFA"; // Un violeta más oscuro para menos trofeos
  };

  const renderCustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0];
      return (
        <div className="bg-gray-700 text-white p-2 rounded shadow-lg">
          <p className="text-sm font-semibold">{name}</p>
          <p className="text-sm">Miembros: {value}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-gray-800 shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4 text-pink-400">Distribución de Trofeos por Miembro</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          barSize={30} // Ajusta el tamaño de la barra si es necesario
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" stroke="#a855f7" tick={{ fill: 'white' }} />
          <YAxis stroke="#a855f7" tick={{ fill: 'white' }} />
          <Tooltip content={renderCustomTooltip} contentStyle={{ backgroundColor: '#1f2937', border: 'none' }} />
          <Legend />
          <Bar 
            dataKey="value" 
            fill="#D6BCFA" 
            shape={({ x, y, width, height, fill, value }) => (
              <rect
                x={x}
                y={y}
                width={width}
                height={height}
                fill={getBarColor(value)}
              />
            )}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TrophyDistributionChart;
