// src/components/Campus/app.js (Actualización de las rutas principales)
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import HomePage from './components/home/HomePage';

import SSLDocumentation from './documents/SSLDocumentation';
import FiftyInteractiveUniverse from './components/home/FiftyUniverse';

import EsportsInfo from './components/verticals/EsportsInfo';
import RecordsInfo from './components/verticals/RecordsInfo';
import NewsInfo from './components/verticals/NewsInfo';
import NNdNandoPage from './components/FiftyRecords/artists/nNd_Nando/nNd_Nando';
import FeedbackButton from './components/common/FeedbackButton';
import Dashboard from './components/ClashRoyale/Dashboard';
import FormClanContact from './components/ClashRoyale/fromClanContact';
import TrainingApp from './components/training/app';


// Componente que incluye Header y Footer
const LayoutWithHeaderFooter = ({ children }) => (
  <div className="min-h-screen bg-gray-800">
    <Header />
    {children}
    <FeedbackButton />
    <Footer />
  </div>
);

// Componente para rutas no encontradas
const NotFound = () => (
  <div className="text-center p-8">
    <h1 className="text-4xl font-bold text-red-500">404</h1>
    <p className="text-xl text-gray-400">Página no encontrada.</p>
  </div>
);

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Rutas con Header y Footer */}
        <Route path="/" element={<LayoutWithHeaderFooter><HomePage /></LayoutWithHeaderFooter>} />
        <Route path="/documents/SSL" element={<LayoutWithHeaderFooter><SSLDocumentation /></LayoutWithHeaderFooter>} />
        <Route path="/fiftyuniverse" element={<LayoutWithHeaderFooter><FiftyInteractiveUniverse /></LayoutWithHeaderFooter>} />
        <Route path="/esports-info" element={<LayoutWithHeaderFooter><EsportsInfo /></LayoutWithHeaderFooter>} />
        <Route path="/records-info" element={<LayoutWithHeaderFooter><RecordsInfo /></LayoutWithHeaderFooter>} />
        <Route path="/news-info" element={<LayoutWithHeaderFooter><NewsInfo /></LayoutWithHeaderFooter>} />
        <Route path="/Nando" element={<LayoutWithHeaderFooter><NNdNandoPage /></LayoutWithHeaderFooter>} />
        <Route path="/ClashRoyale/Dashboard" element={<LayoutWithHeaderFooter><Dashboard /></LayoutWithHeaderFooter>} /> 
        <Route path="/contact-clans" element={<LayoutWithHeaderFooter><FormClanContact /></LayoutWithHeaderFooter>} /> 
        <Route path="/training" element={<LayoutWithHeaderFooter><TrainingApp /></LayoutWithHeaderFooter>} />
        {/* Ruta de captura para redirigir en caso de error */}
        <Route path="*" element={<LayoutWithHeaderFooter><NotFound /></LayoutWithHeaderFooter>} />
      </Routes>
    </Router>
  );
};

export default App;
