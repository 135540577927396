import React, { useState, useEffect } from 'react';
import { FaDiscord } from 'react-icons/fa';
import { Dumbbell, Gamepad2, Music, Home, Newspaper } from 'lucide-react'; // Importar el ícono de periódico

const Sidebar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(!isMobile); // Expandido por defecto en desktop

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setSidebarExpanded(window.innerWidth > 768); // Automáticamente expandido en pantallas más grandes
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full bg-gray-800 p-4 flex flex-col items-center space-y-6 transition-all duration-300 z-50 ${sidebarExpanded ? 'w-64' : 'w-16'}`}
    >
      <a href="/" className="text-white hover:text-purple-400 transition duration-300 flex items-center">
        <Home size={32} />
        {sidebarExpanded && <span className="ml-2 text-white">Inicio</span>}
      </a>
      <a href="/esports-info" className="text-white hover:text-purple-400 transition duration-300 flex items-center">
        <Gamepad2 size={32} />
        {sidebarExpanded && <span className="ml-2 text-white">Esports</span>}
      </a>
      <a href="/records-info" className="text-white hover:text-purple-400 transition duration-300 flex items-center">
        <Music size={32} />
        {sidebarExpanded && <span className="ml-2 text-white">Records</span>}
      </a>
      {/* Nuevo ícono de Noticias */}
      <a href="/news-info" className="text-white hover:text-purple-400 transition duration-300 flex items-center">
        <Newspaper size={32} /> {/* Icono de periódico */}
        {sidebarExpanded && <span className="ml-2 text-white">Noticias</span>}
      </a>
      <a href="/training" className="text-white hover:text-purple-400 transition duration-300 flex items-center">
        <Dumbbell size={32} />
        {sidebarExpanded && <span className="ml-2 text-white">Fitness</span>}
      </a>
      <a href="https://discord.gg/ZkyZbAbSDr" target="_blank" rel="noopener noreferrer" className="text-white hover:text-purple-400 transition duration-300 flex items-center">
        <FaDiscord size={32} />
        {sidebarExpanded && <span className="ml-2 text-white">Discord</span>}
      </a>
      {isMobile && (
        <button onClick={toggleSidebar} className="text-white mt-auto">
          {sidebarExpanded ? '←' : '→'}
        </button>
      )}
    </div>
  );
};

export default Sidebar;
