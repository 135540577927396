import React from 'react';
import { Crown } from 'lucide-react';  // Asegúrate de que la ruta de importación sea correcta

const FiftyRoyalLogo = () => {
  return (
    <div className="relative z-5 flex flex-col items-center justify-center h-full text-center">
      {/* Definición de la animación en línea */}
      <style>{`
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }
        .animate-float {
          animation: float 3s ease-in-out infinite;
        }
      `}</style>

      <div className="animate-float">
        <Crown className="w-16 h-16 text-yellow-400 mb-4 mt-4" />
      </div>
      
      <p className="text-sm font-medium mb-4">
      </p>
    </div>
  );
};

export default FiftyRoyalLogo;
