import React, { useState, useEffect, useRef, useCallback } from 'react';
import sendData from '../utils/sendData';

const MIN_TITLE_LENGTH = 5;
const MIN_DESCRIPTION_LENGTH = 20;

const validateForm = (title, description) => {
  const errors = {};
  if (title.length < MIN_TITLE_LENGTH) {
    errors.title = `El título debe tener al menos ${MIN_TITLE_LENGTH} caracteres`;
  }
  if (description.length < MIN_DESCRIPTION_LENGTH) {
    errors.description = `La descripción debe tener al menos ${MIN_DESCRIPTION_LENGTH} caracteres`;
  }
  return errors;
};

const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const pixelRatio = window.devicePixelRatio;
  const language = navigator.language;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const cookiesEnabled = navigator.cookieEnabled;
  const doNotTrack = navigator.doNotTrack;
  const onLine = navigator.onLine;
  const connectionType = navigator.connection ? navigator.connection.effectiveType : 'Unknown';
  const memoryInfo = navigator.deviceMemory ? `${navigator.deviceMemory} GB` : 'Unknown';
  const storageEstimate = navigator.storage.estimate();
  const storageQuota = storageEstimate.quota ? `${Math.round(storageEstimate.quota / (1024 * 1024))} MB` : 'Unknown';
  const storageUsage = storageEstimate.usage ? `${Math.round(storageEstimate.usage / (1024 * 1024))} MB` : 'Unknown';
  const touchPoints = navigator.maxTouchPoints;
  const hardwareConcurrency = navigator.hardwareConcurrency;
  const sessionStorageAvailable = !!window.sessionStorage;
  const localStorageAvailable = !!window.localStorage;

  return `
    - Navegador: ${userAgent}
    - Plataforma: ${platform}
    - Resolución de pantalla: ${screenWidth}x${screenHeight}
    - Relación de píxeles del dispositivo: ${pixelRatio}
    - Idioma: ${language}
    - Zona horaria: ${timeZone}
    - Cookies habilitadas: ${cookiesEnabled}
    - Do Not Track: ${doNotTrack}
    - En línea: ${onLine}
    - Tipo de conexión: ${connectionType}
    - Memoria del dispositivo: ${memoryInfo}
    - Cuota de almacenamiento: ${storageQuota}
    - Uso de almacenamiento: ${storageUsage}
    - Puntos de contacto máximos: ${touchPoints}
    - Núcleos de CPU: ${hardwareConcurrency}
    - SessionStorage disponible: ${sessionStorageAvailable}
    - LocalStorage disponible: ${localStorageAvailable}
    - User Agent: ${userAgent}
  `;
};

const FeedbackButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  useEffect(() => {
    const url = process.env.REACT_APP_DISCORD_WEBHOOK_FEEDBACK;
    if (!url) {
      console.error('Webhook URL is not defined in environment variables');
    }
    setWebhookUrl(url || '');
  }, []);

  const handleSubmit = useCallback(async () => {
    const formErrors = validateForm(title, description);
    setErrors(formErrors);
    if (Object.keys(formErrors).length > 0) {
      return;
    }

    if (!webhookUrl) {
      setMessage({ type: 'error', text: 'La URL del webhook no está configurada correctamente. Por favor, contacta al administrador del sitio.' });
      return;
    }

    const originUrl = window.location.href;
    const deviceInfo = getDeviceInfo();

    const formData = new FormData();
    formData.append('content', `Nuevo feedback recibido:
    - Título: ${title}
    - Descripción: ${description}
    - URL de origen: ${originUrl}
    
Información del dispositivo:
${deviceInfo}`);

    if (attachment) {
      formData.append('file', attachment);
    }
    
    try {
      const success = await sendData(
        webhookUrl,
        formData,
        '¡Gracias por tu feedback! Lo hemos recibido y lo revisaremos pronto.',
        'Lo sentimos, hubo un problema al enviar tu feedback. Por favor, inténtalo de nuevo más tarde.'
      );
      
      if (success) {
        setMessage({ type: 'success', text: '¡Gracias por tu feedback! Lo hemos recibido y lo revisaremos pronto.' });
        setIsOpen(false);
        setTitle('');
        setDescription('');
        setAttachment(null);
        setErrors({});
        setTimeout(() => setMessage(null), 3000);
      }
    } catch (error) {
      console.error('Error al enviar feedback:', error);
      setMessage({ type: 'error', text: `Ocurrió un error al enviar el feedback. Detalles: ${error.message}` });
    }
  }, [webhookUrl, title, description, attachment]);

  const handleFileChange = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      setAttachment(file);
    }
  }, []);

  const removeAttachment = useCallback(() => {
    setAttachment(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, []);

  const toggleFeedback = useCallback(() => {
    setIsOpen((prev) => !prev);
    setErrors({});
  }, []);

  const buttonClass = "px-4 py-2 rounded transition-colors duration-300 text-center flex-1";

  return (
    <>
      <button 
        onClick={toggleFeedback}
        className="fixed bottom-4 right-4 w-16 h-16 rounded-full bg-purple-600 hover:bg-purple-700 text-white shadow-lg transition-all duration-300 ease-in-out transform hover:scale-110 flex items-center justify-center z-50"
      >
        💬
      </button>
      
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold text-purple-600">¡Tu opinión nos importa!</h2>
              <button onClick={toggleFeedback} className="text-gray-500 hover:text-gray-700">
                ❌
              </button>
            </div>
            <div className="mb-4">
              <input
                className={`w-full border ${errors.title ? 'border-red-500' : 'border-purple-300'} p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-500`}
                placeholder="Título de tu feedback"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                maxLength={100}
                required
              />
              {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
            </div>
            <div className="mb-4">
              <textarea
                className={`w-full border ${errors.description ? 'border-red-500' : 'border-purple-300'} p-2 rounded focus:outline-none focus:ring-2 focus:ring-purple-500 resize-none`}
                placeholder="Cuéntanos más detalles..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                maxLength={500}
                rows={4}
                required
              />
              {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description}</p>}
            </div>
            {attachment && (
              <div className="flex items-center justify-between mb-4 bg-green-100 p-2 rounded">
                <span className="text-green-600 truncate">{attachment.name}</span>
                <button onClick={removeAttachment} className="text-red-500 hover:text-red-700 ml-2">
                  ❌
                </button>
              </div>
            )}
            <div className="flex justify-between items-center space-x-2">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
                id="file-upload"
                accept="image/*"
              />
              <label
                htmlFor="file-upload"
                className={`${buttonClass} ${attachment ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-300 cursor-pointer'}`}
              >
                📎 Adjuntar
              </label>
              <button 
                className={`${buttonClass} bg-gray-200 hover:bg-gray-300`}
                onClick={toggleFeedback}
              >
                Cancelar
              </button>
              <button 
                className={`${buttonClass} bg-purple-600 hover:bg-purple-700 text-white`}
                onClick={handleSubmit}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      )}
      {message && (
        <div className={`fixed bottom-4 left-4 p-4 rounded ${message.type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white z-50`}>
          {message.text}
        </div>
      )}
    </>
  );
};

export default FeedbackButton;