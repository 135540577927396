import React from 'react';
import { motion } from 'framer-motion';

const musicCases = [
  {
    title: "DJ Alex Luna - Reves de paris",
    coverImage: "/images/RevesDeParis.webp",
    link: "https://distrokid.com/hyperfollow/djalexluna/rves-de-paris"
  },
  {
    title: "Fifty Groove - Echoes of The Journey",
    coverImage: "/images/EchoesoftheJourney.webp",
    link: "https://distrokid.com/hyperfollow/fiftygroove/echoes-of-the-journey"
  },
  {
    title: "Fifty Groove - Hey, lève les mains",
    coverImage: "/images/HeyHey.webp",
    link: "https://distrokid.com/hyperfollow/fiftygroove/hey-lve-les-mains"
  }
];

const RecordsInfo = ({ sidebarExpanded }) => {
  return (
    <div className={`flex min-h-screen bg-gray-900 text-white transition-all duration-300 ${sidebarExpanded ? 'ml-64' : 'ml-16'} p-8`}>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="max-w-4xl w-full space-y-12"
      >
        <header className="text-center">
          <motion.h1 
            className="text-5xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-red-500"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            Fifty Records
          </motion.h1>
          <p className="text-xl text-gray-300">
            Descubre y apoya a los artistas que están definiendo el sonido del futuro.
          </p>
        </header>

        <motion.section 
          className="bg-gray-800 bg-opacity-50 p-8 rounded-2xl shadow-2xl backdrop-blur-sm border border-gray-700"
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }}
        >
          <h2 className="text-3xl font-bold mb-6 text-center">Nuestra Misión</h2>
          <p className="text-lg mb-6 text-center text-gray-300">
            En Fifty Royal Records, nos dedicamos a impulsar el talento musical emergente. Ofrecemos una plataforma única para artistas que desean llevar su música a nuevas alturas y redefinir la industria musical.
          </p>

          <h3 className="text-2xl font-bold mb-4 text-center">Beneficios Exclusivos</h3>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {["Distribución Global", "Colaboraciones Únicas", "Soporte Creativo", "Comunidad Vibrante"].map((benefit, index) => (
              <motion.li 
                key={index}
                className="flex items-center space-x-3 bg-gray-700 bg-opacity-50 p-4 rounded-lg"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <span className="text-purple-400 text-2xl">✦</span>
                <span className="text-lg">{benefit}</span>
              </motion.li>
            ))}
          </ul>
        </motion.section>

        <motion.section
          className="bg-gradient-to-r from-blue-600 to-purple-600 p-8 rounded-2xl shadow-2xl text-center"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <h3 className="text-3xl font-bold mb-4">¡Únete a Fifty Records!</h3>
          <p className="text-xl mb-6">
            Lleva tu carrera musical al siguiente nivel. Juntos, crearemos el soundtrack del futuro.
          </p>
        </motion.section>

        <section>
          <h2 className="text-4xl font-bold mb-8 text-center">Nuestros Éxitos</h2>
          <div className="space-y-6">
            {musicCases.map((track, index) => (
              <motion.div 
                key={index}
                className="bg-gray-800 bg-opacity-50 p-6 rounded-xl shadow-lg flex items-center justify-between backdrop-blur-sm border border-gray-700"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                whileHover={{ scale: 1.03 }}
              >
                <div className="flex items-center space-x-6">
                  <img src={track.coverImage} alt={`${track.title} Cover`} className="w-20 h-20 object-cover rounded-lg shadow-md" />
                  <h3 className="text-xl font-bold">{track.title}</h3>
                </div>
                <a 
                  href={track.link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="px-6 py-3 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-full text-sm font-bold shadow-lg hover:from-purple-600 hover:to-pink-600 transition duration-300"
                >
                  Escuchar Ahora
                </a>
              </motion.div>
            ))}
          </div>
        </section>
      </motion.div>
    </div>
  );
};

export default RecordsInfo;
