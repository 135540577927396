//fifty-royal-esports\src\components\ClashRoyale\MembersList.js

import React, { useState, useMemo } from 'react';
import { formatLastSeen } from './formatLastSeen';
import ClanActivityStatusBar from './ClanActivityStatusBar';
import clanInfo from './clanInfo.json';  // Importa el JSON que contiene toda la información del clan

const Member = ({ member }) => (
  <div className="bg-gray-700 shadow rounded-lg p-4">
    <h3 className="font-bold text-lg mb-2 text-purple-400">{member.name}</h3>
    <p><strong className="text-blue-400">Rol:</strong> {member.role}</p>
    <p><strong className="text-blue-400">Nivel:</strong> {member.expLevel}</p>
    <p><strong className="text-blue-400">Trofeos:</strong> {member.trophies}</p>
    <p><strong className="text-blue-400">Donaciones:</strong> {member.donations}</p>
    <p><strong className="text-blue-400">Última Conexión:</strong> {formatLastSeen(member.lastSeen)}</p>
  </div>
);

const MembersList = () => {
  const [filter, setFilter] = useState('all');  // Estado para manejar el filtro

  const filteredMembers = useMemo(() => {
    const now = new Date();
    const twoDaysAgo = new Date(now.getTime() - 2 * 24 * 60 * 60 * 1000);
    const fiveDaysAgo = new Date(now.getTime() - 5 * 24 * 60 * 60 * 1000);

    return clanInfo.memberList.filter(member => {
      const lastSeenDate = new Date(member.lastSeen.replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})\.000Z/, '$1-$2-$3T$4:$5:$6.000Z'));

      if (filter === 'active') {
        return lastSeenDate > twoDaysAgo;
      } else if (filter === 'semiActive') {
        return lastSeenDate > fiveDaysAgo && lastSeenDate <= twoDaysAgo;
      } else if (filter === 'inactive') {
        return lastSeenDate <= fiveDaysAgo;
      }
      return true;  // Si el filtro es "all", muestra todos los jugadores
    });
  }, [filter]);

  return (
    <div className="bg-gray-800 shadow-lg rounded-lg p-6">
      <ClanActivityStatusBar members={clanInfo.memberList} onFilterChange={setFilter} />
      <h2 className="text-2xl font-semibold mb-4 text-pink-400">Miembros del Clan</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredMembers.map(member => (
          <Member key={member.tag} member={member} />
        ))}
      </div>
    </div>
  );
};

export default MembersList;