import React from 'react';


const SSLDocumentation = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        <div className="bg-white shadow-lg rounded-lg p-8 my-5 mx-auto max-w-4xl">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">Documentación del Manejo de Certificados SSL</h1>
          
          <h2 className="text-2xl text-blue-600">Identificación del Error</h2>
          <p className="text-gray-700 mt-2">
            El problema inicial fue identificado por el error <code className="bg-gray-100 p-1 rounded">net::ERR_CERT_DATE_INVALID</code>. 
            Este error indica que el certificado SSL del sitio no era válido debido a un problema con la fecha del certificado, 
            lo cual podría deberse a una expiración del certificado o a una configuración incorrecta del sistema que afecta la validación de la fecha.
          </p>

          <h2 className="text-2xl text-blue-600 mt-6">Pasos para la Resolución</h2>
          <ol className="list-decimal list-inside space-y-2 mt-3">
            <li>
              <strong>Verificación del Certificado:</strong> 
              Utilizamos el comando <code className="bg-gray-100 p-1 rounded">sudo certbot certificates</code> para verificar la validez actual de los certificados instalados.
            </li>
            <li>
              <strong>Renovación del Certificado:</strong> 
              Intentamos la renovación automática mediante el comando <code className="bg-gray-100 p-1 rounded">sudo certbot renew</code>, que revisa y renueva los certificados próximos a expirar.
            </li>
            <li>
              <strong>Configuración del Cron Job:</strong> 
              Establecemos un cron job para automatizar el proceso de renovación y garantizar que los certificados se mantengan actualizados sin intervención manual.
              <pre className="bg-gray-100 p-2 rounded my-2 whitespace-pre-wrap"><code>0 2 * * 0 certbot renew --pre-hook "cp -r /etc/letsencrypt/live /backup/letsencrypt_pre" --post-hook "sudo systemctl reload nginx"</code></pre>
            </li>
            <li>
              <strong>Prueba de Renovación:</strong> 
              Realizamos una simulación de renovación para probar todo el proceso sin efectuar cambios reales, asegurando que la configuración es correcta.
            </li>
            <li>
              <strong>Pruebas Finales:</strong> 
              Confirmamos que todos los pasos se ejecutan correctamente y que no hay errores, garantizando la integridad y la funcionalidad del sistema.
            </li>
          </ol>

          <h2 className="text-2xl text-blue-600 mt-6">Consideraciones Finales</h2>
          <p className="text-gray-700 mt-2">
            Es crucial mantener un monitoreo continuo de los logs de Certbot y del funcionamiento de Nginx para detectar y resolver rápidamente cualquier problema futuro.
            La verificación periódica de las copias de seguridad de los certificados también es recomendada para garantizar la recuperación ante fallos.
          </p>
        </div>
      </main>
    </div>
  );
};

export default SSLDocumentation;
