import React from 'react';
import { FaUserFriends, FaTrophy, FaMapMarkerAlt, FaDonate, FaMedal } from 'react-icons/fa';
import clanInfo from './clanInfo.json';  // Importa el JSON generado

const ClanInfo = () => (
  <div className="bg-gray-800 shadow-lg rounded-lg p-6 mb-6">
    <h2 className="text-2xl font-semibold mb-4 text-pink-400">Información del Clan</h2>
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 text-gray-300 border-t border-gray-700 pt-4">
      
      <p className="flex items-center border-b border-gray-700 pb-2">
        <FaUserFriends className="text-blue-400 mr-2" />
        <strong className="text-blue-400">Miembros:</strong> 
        <span className="ml-1 text-lg text-white">{clanInfo.members}/50</span>
      </p>

      <p className="flex items-center border-b border-gray-700 pb-2">
        <FaTrophy className="text-yellow-400 mr-2" />
        <strong className="text-blue-400">Trofeos del Clan:</strong> 
        <span className="ml-1 text-lg text-white">{clanInfo.clanScore}</span>
      </p>

      <p className="flex items-center border-b border-gray-700 pb-2">
        <FaMedal className="text-yellow-300 mr-2" />
        <strong className="text-blue-400">Trofeos de Guerra:</strong> 
        <span className="ml-1 text-lg text-white">{clanInfo.clanWarTrophies}</span>
      </p>

      <p className="flex items-center border-b border-gray-700 pb-2">
        <FaMapMarkerAlt className="text-green-400 mr-2" />
        <strong className="text-blue-400">Ubicación:</strong> 
        <span className="ml-1 text-lg text-white">{clanInfo.location.name}</span>
      </p>

      <p className="flex items-center">
        <FaDonate className="text-red-400 mr-2" />
        <strong className="text-blue-400">Donaciones por Semana:</strong> 
        <span className="ml-1 text-lg text-white">{clanInfo.donationsPerWeek}</span>
      </p>

    </div>
  </div>
);

export default ClanInfo;
