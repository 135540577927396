import React from 'react';
import FiftyInteractiveUniverse from './FiftyUniverse';
import Timeline from '../common/Timeline';
import FiftyRoyalLogo from './FiftyRoyalLogo';

const HomePage = () => {
  return (
    <div className="flex min-h-screen bg-gray-900 text-white overflow-x-hidden">
      <main className="flex-grow container mx-auto flex flex-col justify-center items-center ml-16 md:ml-64"> {/* Se ajusta según el sidebar */}
        <FiftyRoyalLogo />
        <FiftyInteractiveUniverse />
        <Timeline />
      </main>
    </div>
  );
};

export default HomePage;
